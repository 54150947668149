import { BaseModel, Guid, RecursivePartial } from '@models/base';

export class IntegrationWebhookEvent extends BaseModel {
    id?: Guid;
    created?: Date;
    modified?: Date;
    createdBy?: Guid;
    setupId?: Guid;
    orgId?: Guid;
    elapsedTime?: number;
    statusCode?: number;
    requestBody?: any;
    requestHeaders?: any;
    appliedTriggerIds?: Guid[];
    errorStr?: string;

    constructor(json?: RecursivePartial<IntegrationWebhookEvent>) {
        super(json);
        this.parseIntegrationWebhookEvent(json || {});
    }

    parse(json: RecursivePartial<IntegrationWebhookEvent>): IntegrationWebhookEvent {
        super.parse(json);
        this.parseIntegrationWebhookEvent(json);
        return this;
    }

    private parseIntegrationWebhookEvent(json: RecursivePartial<IntegrationWebhookEvent>): void {
        IntegrationWebhookEvent.assignFields<IntegrationWebhookEvent>(this, json, [
            'id',
            'createdBy',
            'setupId',
            'orgId',
            'elapsedTime',
            'statusCode',
            'requestBody',
            'requestHeaders',
            'appliedTriggerIds',
            'errorStr',
        ]);
        IntegrationWebhookEvent.assignDates<IntegrationWebhookEvent>(this, json, ['created', 'modified']);
    }
}

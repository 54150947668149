import { Group, PersonOrgDetails, PrivilegeTree, PrivilegeTreeItem, PrivilegeType, PrivilegesCalendar, PrivilegesFile, PrivilegesTask, Project, Role } from "@models/users";
import { SM } from "@models/base";

export function getPrivilegeTree(opt: { tree?: PrivilegeTreeItem[], parent?: PrivilegeTreeItem, idMap?: SM<PrivilegeTreeItem>, granted?: SM<boolean> } = {}): PrivilegeTreeItem[] {
    if (!opt.tree) {
        return [];
    }
    const pt = [];
    for (const pi of opt.tree) {
        const item: PrivilegeTreeItem = { id: pi.id, name: pi.name, desc: pi.desc, danger: pi.danger, global: pi.global };
        if (opt.parent && opt.parent.id) {
            item.path = '' + opt.parent.id;
            item.danger ||= opt.parent.danger;
        }
        item.fullId = (opt.parent && opt.parent.fullId ? opt.parent.fullId + '.' : '') + pi.id;
        if (opt.idMap) {
            opt.idMap[item.fullId] = item;
        }
        if (pi.children?.length) {
            const children = getPrivilegeTree({ tree: pi.children, parent: item, idMap: opt.idMap, granted: opt.granted });
            if (children && children.length > 0) {
                pt.push(item);
                item.children = children;
                item.includes = [];
                children.forEach(ch => {
                    item.includes!.push(ch.fullId!)
                    if (ch.includes) {
                        item.includes!.push(...ch.includes)
                    }
                });
            }
            else if (!opt.granted || opt.granted[item.fullId]) {
                pt.push(item);
            }
        }
        else if (!opt.granted || opt.granted[item.fullId]) {
            pt.push(item);
        }
    }
    return pt;
}

export function setGrantedPrivileges(privs: string[], granted: SM<boolean | SM<boolean>>, exclude?: (priv: string) => boolean): void {
    const idMap = {};
    getPrivilegeTree({ tree: PrivilegeTree[PrivilegeType.All], idMap })
    for (const p of privs) {
        for (const id of Object.keys(idMap)) {
            if (id.startsWith(p) && (!exclude || !exclude(p))) {
                granted[id] = true;
            }
        }
    }
}

export function getAllUserPrivileges(
    userId: string,
    org: PersonOrgDetails,
    groupIds: string[],
    roleIds: string[],
    groupsMap: SM<Group>,
    rolesMap: SM<Role>,
    projectsMap: SM<Project>
): SM<SM<boolean>> {
    const granted: SM<SM<boolean>> = { global: {} };
    const pids = Object.values(projectsMap).filter(p => org.id == p.orgId).map(p => p.id).filter(id => !!id);
    pids.forEach(id => granted[id!] = {});
    if (org.isOwner) {
        setGrantedPrivileges(PrivilegeTree[PrivilegeType.Person].map(pt => pt.id), granted.global);
        pids.forEach(
            id => setGrantedPrivileges(
                PrivilegeTree[PrivilegeType.Person].map(pt => pt.id),
                granted[id!],
                p => !p || p.startsWith('org') && p.startsWith('admin')
            )
        );
    }
    if (org.privs) {
        setGrantedPrivileges(org.privs, granted.global);
        pids.forEach(
            id => setGrantedPrivileges(
                org.privs!,
                granted[id!],
                p => !p || p.startsWith('org') || p.startsWith('admin')
            )
        );
    }
    const rids: SM<boolean> = roleIds.reduce((obj, id) => ({ ...obj, [id!]: true }), {});
    for (const id of groupIds) {
        if (groupsMap[id]?.roleIds?.length) {
            groupsMap[id].roleIds.forEach(id => rids[id] = true);
        }
    }
    for (const id of Object.keys(rids)) {
        const r = rolesMap[id];
        if (r?.privileges?.length) {
            if (r.projectId) {
                if (granted[r.projectId]) {
                    setGrantedPrivileges(
                        r.privileges,
                        granted[r.projectId],
                        p => !p || p.startsWith('org') || p.startsWith('admin')
                    );
                }
            }
            else {
                setGrantedPrivileges(r.privileges, granted.global);
                Object.values(projectsMap).map(p => p.id).forEach(id => {
                    if (granted[id!]) {
                        setGrantedPrivileges(
                            r.privileges,
                            granted[id!],
                            p => !p || p.startsWith('org') || p.startsWith('admin')
                        )
                    }
                });
            }
        }
    }
    const pm = Object.values(projectsMap).filter(p => org.id == p.orgId && (p.managerId == userId || org.isOwner)).map(p => p.id);
    for (const id of pm) {
        if (granted[id!]) {
            granted[id!].__manager = true;
            granted.global.__manager = true;
        }
        setGrantedPrivileges([PrivilegesFile, PrivilegesCalendar, PrivilegesTask].map(pt => pt.id), granted[id!]);
        // setGrantedPrivileges([PrivilegesFile, PrivilegesProject, PrivilegesCalendar, PrivilegesTask].map(pt => pt.id), granted[id!]);
    }
    // console.log('==[ getAllUserPrivileges ]===> userId:', userId, '\n\t\torg:', org, '\n\t\tprojectsMap:', projectsMap, '\n\t\tpm:', pm, '\n\t\trids:', rids, '\n\t\tgranted:', granted);

    return granted;
}

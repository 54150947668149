import { BaseSystemModel, RecursivePartial, SM } from '@models/base';
import { Department } from './department';
import { OrgPlugin } from './org-plugin';

export const PLUGIN_VIZORRO_ID = 'E430D4DE-2943-4428-909D-D0264BEE64CD';

export interface IOrgTpl {
    name?: string;
    avatar?: string;
}

export class Org extends BaseSystemModel {
    _type = BaseSystemModel.Type.Org;

    name!: string;
    avatar?: string;
    ownerId: string = '';
    ownerEmail?: string = '';
    departments: Department[] = [];
    plugins: OrgPlugin[] = [];
    defaultDepartmentId?: string;

    slotsLimit: number = 0;
    slotsNext: number = 0;
    activeUsers: number = 0;
    activeInvites: number = 0;
    suspended?: boolean;
    options?: SM<string>;

    _depsMap: SM<Department> = {};
    _vizorro?: boolean;

    constructor(json?: RecursivePartial<Org>) {
        super(json);
        this.parseOrg(json || {});
    }

    parse(json: RecursivePartial<Org>): Org {
        super.parse(json);
        this.parseOrg(json);
        return this;
    }

    private parseOrg(json: RecursivePartial<Org>): void {
        Org.assignFields<Org>(this, json, [
            'name',
            'avatar',
            'ownerId',
            'ownerEmail',
            'defaultDepartmentId',
            'slotsLimit',
            'slotsNext',
            'activeUsers',
            'activeInvites',
            'suspended',
            'options'
        ]);
        Org.assignClassArrays<Org>(this, json, { departments: Department, plugins: OrgPlugin });
        this.departments.forEach(d => this._depsMap[d.id!] = d);
        if (this.plugins?.findIndex(p => p.id == PLUGIN_VIZORRO_ID) != -1) {
            this._vizorro = true;
        }
    }
}

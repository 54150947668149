/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';

import { BaseLogging } from '@base/base-logging';
import {
    CalendarEvent, Chat, ChatCall, ChatInvitation, ChatMentionMessage, ChatMessage, ChatStats, DeviceInfo, EventAttendeeState,
    FcmSubscription, Guid, HealthResponse, IChatTpl, IOrgInfo, ITaskTpl, IUserOrgStatusTypeTpl, IVzFileTpl,
    KanbanBoard, MentionsCounters, OrgBalanceChange, OrgInfo, OrgInvoice, OrgPayPlan, Person, SM, SYSTEM_USER_ID,
    Tag, Task, TaskAction, TaskActionErrorText, TaskActionType, TaskListType, TaskStats, TasksCounters, UrlPreview,
    UserNotification, UserNotificationSettings, UserOrgStatus, UserOrgStatusType, VzFile, VzFilesOrgStats,
    Integration, SigninResponse, ISearchTasksOpts, VzTag, createTaskAction, OrgPaidCall, VzTip, VzTariff,
    IntegrationSetup, IntegrationTrigger, VzSystemModule, ProjectInprogressStats, IntegrationWebhookEvent
} from '@models';
import { HttpApiError } from '@models/api/http-api-error';
import { RequestCallbacks } from '@models/api/request-callbacks';
import {
    ApiMethod, DEF_ERRORS, Delete, Get, Post, Put, TResponseType, TVzResponse, VzServices, emitArray,
    emitField, emitOptional, sendRequest, getHttpParams
} from '@models/utils/http-helpers';
import { AuthService } from './auth.service';
import { StoreService } from './store.service';
import { ProjectTaskStats } from '@root/_models/tasks/project-task-stats';

// const GE = (s: number): string => `/events/return_error/${s}?req=`;

@Injectable({ providedIn: 'root' })
@Tag('ApiService')
// @EmulatorStore('kaban', {})
export class ApiService extends BaseLogging {

    private _baseUrl: string | undefined = this.__ENV.urls.api;
    private _conf: SM<() => VzServices> = {};
    lastRequest: number = new Date().getTime();

    RD: SM<ApiMethod<any, any>>;
    private __req?: ApiMethod<any, any> | null;

    constructor(
        private _http: HttpClient,
        private _auth: AuthService,
        private _store: StoreService,
    ) {
        super();
        this._L('constructor');
        this.RD = (this as any).__RD;
        if (this.__ENV.env != 'prod') {
            this._store.state('url').subscribe(state => {
                this._baseUrl = state.apiBaseUrl;
                this._L('constructor', 'baseUrl:', this._baseUrl);
            });
        }
        const conf: () => VzServices = () => ({
            baseUrl: '',
            http: this._http,
            L: this._L,
            W: this._W,
            updateLastRequest: () => this.lastRequest = new Date().getTime(),
            authTokenState: this._store.authTokenState,
            getLastActivity: () => new Date(this._store.getLastActivity()),
            getRefreshToken: () => this._store.getState('user', st => st.refreshToken),
            getRefreshingAuthToken: () => this._auth.refreshingAuthToken,
            setRefreshingAuthToken: rat => this._auth.refreshingAuthToken = rat,
            getUserId: () => this._store.getState('user', st => st.userId),
            gotNewToken: token => this._store.patchState('user', { authToken: token }),
            renewAuthToken: (userId, refreshToken, lastActivity) => this._auth.renewAuthToken(userId, refreshToken, lastActivity)
        });
        this._conf.api = () => ({ ...conf(), baseUrl: this._baseUrl! });
        this._conf.app = () => ({ ...conf(), baseUrl: './' });
        this._L('constructor', 'RD:', this.RD);
    }

    sendRequest<T extends TVzResponse>(id: string, ...args: any): Observable<T> {
        return sendRequest(this.RD[id], this._conf[this.RD[id].cfg!] || this._conf.api, ...args);
    }

    getBaseUrl(reqId: string): string {
        const q = new Map<string, Task>();
        q.values()
        return (this._conf[this.RD[reqId].cfg!] || this._conf.api)().baseUrl;
    }

    // * ######################################################################################################### Health Check
    @Get('healthCheck', (service: string) => `/health/${service}`, {
        desc: 'Gets the state of the given Vizorro service',
        res: {
            errorTitle: 'Health check error',
            clazz: HealthResponse
        }
    }) // @ts-ignore
    healthCheck(service: string = 'tasks'): Observable<HealthResponse> {}


    // * ######################################################################################################### Tasks
    @Get('getTaskList', '/tasks', {
        desc: 'Получить список задач по типу',
        params: (type: TaskListType, orgId: Guid) => type as any == 'support' && orgId == SYSTEM_USER_ID
            ? new HttpParams({ fromObject: { type } })
            : new HttpParams({ fromObject: { type, orgId } }),
        res: {
            errorTitle: 'Не удалось получить список задач',
            parser: emitArray(Task)
        }
    }) // @ts-ignore
    getTaskList(type: TaskListType, orgId: Guid): Observable<Task[] | undefined> {}

    @Post('getTasks', '/tasks/list', {
        desc: 'Получить список задач по массиву Id',
        body: (ids: string[]) => ids,
        res: {
            errorTitle: 'Не удалось получить запрошенные задачи',
            parser: emitArray(Task)
        }
    }) // @ts-ignore
    getTasks(ids: string[]): Observable<Task[] | undefined> {}

    @Post('createTask', '/tasks', {
        desc: 'Создает новую задачу',
        body: (task: ITaskTpl) => task,
        res: {
            errorTitle: 'Не удалось создать задачу',
            clazz: Task,
            errors: {
                413: 'Слишком большой размер текста задачи и/или сниппетов'
            }
        },
        bodySizeLimit: 1024 * 1024
    }) // @ts-ignore
    createTask(task: ITaskTpl): Observable<Task> {}

    @Get('getTask', (id: Guid, orgId?: Guid) => `/tasks/${encodeURIComponent(id)}`, {
        desc: 'Получить содержимое задачи по Id или shortId',
        params: (id: Guid, orgId?: Guid) => orgId ? { orgId } : undefined,
        res: {
            errorTitle: 'Не удалось получить сведения о задаче',
            clazz: Task
        }
    }) // @ts-ignore
    getTask(id: Guid, orgId?: Guid): Observable<Task> {}

    @Delete('deleteTask', (id: string) => `/tasks/${id}`, {
        desc: 'Удаляет задачу по Id',
        res: {
            errorTitle: 'Задача не была удалена',
        }
    }) // @ts-ignore
    deleteTask(id: string): Observable<void> {}

    @Put('readTask', (id: string) => `/tasks/${id}/read`, {
        desc: 'Помечает задачу с данным Id как прочитанную',
        res: {
            errorTitle: 'Задача не была помечена как прочитанная',
        }
    }) // @ts-ignore
    readTask(id: string): Observable<void> {}

    @Put('removeFromInbox', (id: string) => `/tasks/${id}/remove`, {
        desc: 'Убирает задачу с данным Id из списка "Входящие"',
        res: {
            errorTitle: 'Задача не была убрана из "Входящих"',
        }
    }) // @ts-ignore
    removeFromInbox(id: string): Observable<void> {}

    @Put('setTaskConditions', (id: string, conditions: SM<any>) => `/tasks/${id}/conditions`, {
        desc: 'Устанавливает флаги задачи (favorite и т.д.)',
        body: (_: string, conditions: SM<any>) => conditions,
        res: {
            errorTitle: (id: string, conditions: SM<any>) => 'Не удалось изменить флаг ' + (conditions?.favorite != null ? '"Избранное" ' : '') + 'у задачи',
        }
    }) // @ts-ignore
    setTaskConditions(id: string, conditions: SM<any>): Observable<void> {}

    @Post('addActions', <T extends TaskAction>(taskId: string, actions: Partial<T>[]) => `/tasks/${taskId}/actions`, {
        desc: 'Добавляет массив акций к задаче',
        body: <T extends TaskAction>(taskId: string, actions: Partial<T>[]) => actions,
        res: {
            errorTitle: 'Не удалось изменить задачу',
            parser: <T extends TaskAction>(data: any, err: HttpApiError) => {
                const arr: T[] = [];
                if (data && Array.isArray(data)) {
                    for (const item of data) {
                        const action = createTaskAction(item);
                        if (action) {
                            arr.push(action as any);
                        }
                    }
                    return of(arr);
                }
                else {
                    err.text = 'Неправильный формат данных в ответе сервера.';
                    return throwError(() => err);
                }
            },
            errors: {
                _: (err, tid, actions) => {
                    let txt = 'Не удалось изменить задачу';
                    if (actions?.length) {
                        const at: TaskActionType = actions[0].type!;
                        if (TaskActionErrorText[at]) {
                            txt = TaskActionErrorText[at];
                        }
                    }
                    err.setTitle(txt);
                    return err.text;
                },
            },
        },
        bodySizeLimit: 512 * 1024
    }) // @ts-ignore
    addActions<T extends TaskAction>(taskId: string, actions: Partial<T>[]): Observable<T[]> {}

    @Get('getTaskCounters', (orgId: Guid) => `/tasks/counters/${orgId}`, {
        desc: 'Получить содержимое задачи по Id или shortId',
        params: (id: string, orgId?: string) => orgId ? { orgId } : undefined,
        res: {
            errorTitle: 'Не удалось получить счетчики задач',
            clazz: TasksCounters
        }
    }) // @ts-ignore
    getTaskCounters(orgId: Guid): Observable<TasksCounters> {}

    @Post('getTasksStats', '/tasks/stats', {
        desc: 'Получить статистику использования ресурсов задачами',
        body: (orgId: Guid) => ({ orgId }),
        res: {
            errorTitle: 'Не удалось получить статистику по задачам',
            parser: emitArray(TaskStats)
        }
    }) // @ts-ignore
    getTasksStats(orgId: Guid): Observable<TaskStats[] | undefined> {}

    @Get('getProjectTasksStats', (projectId: Guid) => `/tasks/stats/project/${projectId}/states`, {
        desc: 'Получить статистику по состоянию задач в проекте',
        res: {
            errorTitle: 'Не удалось получить статистику по состоянию задач в проекте',
            clazz: ProjectTaskStats
        }
    }) // @ts-ignore
    getProjectTasksStats(projectId: Guid): Observable<ProjectTaskStats> {}

    @Get('getProjectInprogressStats', (projectId: Guid) => `/tasks/stats/project/${projectId}/inprogress`, {
        desc: 'Получить статистику по задачам в работе',
        res: {
            errorTitle: 'Не удалось получить статистику по задачам в работе',
            parser: emitArray(ProjectInprogressStats)
        }
    }) // @ts-ignore
    getProjectInprogressStats(projectId: Guid): Observable<ProjectInprogressStats[]> {}

    @Get('getTaskActions', (id: string) => `/tasks/${id}/actions`, {
        desc: 'Получить список акций указанной задачи (журнал)',
        params: (id: string, start?: string, limit?: number) => {
            const httpParams: SM<string> | undefined = start != undefined ? {} : undefined;
            if (httpParams && start) {
                httpParams.start = start;
                if (limit != undefined) {
                    httpParams.limit = '' + limit;
                }
            }
            return httpParams;
        },
        res: {
            errorTitle: 'Не удалось получить журнал задачи',
            parser: (data, err) => {
                const arr: TaskAction[] = [];
                if (data && Array.isArray(data)) {
                    for (const item of data) {
                        const action = createTaskAction(item);
                        if (action) {
                            arr.push(action as any);
                        }
                    }
                    return of(arr)
                }
                else {
                    err.text = 'Неправильный формат данных в ответе сервера.';
                    return throwError(() => err);
                }
            },
            errors: { ...DEF_ERRORS, 200: '' }
        },
    }) // @ts-ignore
    getTaskActions(id: string, start?: string, limit?: number): Observable<TaskAction[]> {}

    @Post('createTaskChat', (id: Guid) => `/tasks/${id}/chat`, {
        desc: 'Создает чат прикрепленный к указанной задаче для ее обсуждения',
        body: {},
        res: {
            errorTitle: 'Не удалось создать чат для обсуждения задачи',
            parser: emitField('chatId')
        }
    }) // @ts-ignore
    createTaskChat(id: Guid): Observable<Guid> {}

    @Post('searchTasks', '/tasks/search', {
        desc: 'Ищет задачи по тексту темы либо shortId с учетом проекта или без',
        body: (orgId: Guid, text: string, opts?: ISearchTasksOpts) => {
            if (opts && opts.start == 0) {
                delete opts.start;
            }
            const body = { orgId, text, ...opts };
            return body;
        },
        res: {
            errorTitle: 'Ошибка при поиске по задачам',
            parser: emitArray(Task)
        }
    }) // @ts-ignore
    searchTasks(orgId: Guid, text: string, opts?: ISearchTasksOpts): Observable<Task[]> {}

    @Get('getKanbanBoardsList', '/boards/kanban', {
        desc: 'Получить список всех доступных пользователю Канбан досок в указанной организации',
        res: {
            errorTitle: 'Не удалось получить список Канбан досок',
            parser: emitArray(KanbanBoard)
        }
    }) // @ts-ignore
    getKanbanBoardsList(): Observable<KanbanBoard[]> {}

    @Post('createKanbanBoard', '/boards/kanban', {
        desc: 'Создает новую Канбан доску',
        body: (board: KanbanBoard) => board,
        res: {
            errorTitle: 'Не удалось создать Канбан доску',
            clazz: KanbanBoard
        }
    }) // @ts-ignore
    createKanbanBoard(board: KanbanBoard): Observable<KanbanBoard> {}

    @Get('getKanbanBoard', (id: Guid, orgId?: Guid) => `/boards/kanban/${id}`, {
        desc: 'Получить Канбан доску по Id',
        params: (id: Guid, orgId?: Guid) => orgId ? { orgId } : undefined,
        res: {
            errorTitle: 'Не удалось получить сведения о Канбан доске',
            clazz: KanbanBoard
        }
    }) // @ts-ignore
    getKanbanBoard(id: Guid, orgId?: Guid): Observable<KanbanBoard> {}

    @Delete('deleteKanbanBoard', (id: string) => `/boards/kanban/${id}`, {
        desc: 'Удаляет Канбан доску по Id',
        res: {
            errorTitle: 'Канбан доска не была удалена',
        }
    }) // @ts-ignore
    deleteKanbanBoard(id: string): Observable<void> {}

    @Put('updateKanbanBoard', (id: string, board: KanbanBoard) => `/boards/kanban/${id}`, {
        desc: 'Изменяет настройки Канбан доски',
        body: (id: string, board: KanbanBoard) => board,
        res: {
            errorTitle: 'Канбан доска не была изменена',
        }
    }) // @ts-ignore
    updateKanbanBoard(id: string, board: KanbanBoard): Observable<void> {}



    // * ######################################################################################################### Chats
    @Post('createChat', '/chats', {
        desc: 'Создать новый чат',
        body: (chat: IChatTpl) => chat,
        res: {
            errorTitle: 'Не удалось создать чат',
            clazz: Chat
        }
    }) // @ts-ignore
    createChat(chat: IChatTpl): Observable<Chat> {}

    @Put('updateChat', (id: string, chat: IChatTpl) => `/chats/${id}`, {
        desc: 'Изменить параметры чата',
        body: (id: string, chat: IChatTpl) => chat,
        res: {
            errorTitle: 'Не удалось изменить параметры чата',
            clazz: Chat
        }
    }) // @ts-ignore
    updateChat(id: string, chat: IChatTpl): Observable<Chat> {}

    @Delete('finishChat', (id: string) => `/chats/${id}`, {
        desc: 'Переводит чат с указанным Id в статус "Завершен"',
        res: {
            errorTitle: 'Не удалось завершить чат',
        }
    }) // @ts-ignore
    finishChat(id: string): Observable<void> {}

    @Get('getChatsList', '/chats', {
        desc: 'Получить активные (или все) чаты',
        params: (all?: true) => all === true ? { all: 'true' } : undefined,
        res: {
            errorTitle: all => `Не удалось получить список ${all === true ? 'всех' : 'активных'} чатов`,
            parser: emitArray(Chat)
        }
    }) // @ts-ignore
    getChatsList(all?: true): Observable<Chat[] | undefined> {}

    @Post('getChats', '/chats/list', {
        desc: 'Получить чаты с указанными Id',
        res: {
            errorTitle: 'Не удалось получить чаты',
            parser: emitArray(Chat)
        }
    }) // @ts-ignore
    getChats(ids: string[]): Observable<Chat[] | undefined> {}

    @Get('getChat', (id: string) => `/chats/${id}`, {
        desc: 'Получить чат по Id',
        res: {
            errorTitle: 'Не удалось получить чат',
            clazz: Chat
        }
    }) // @ts-ignore
    getChat(id: string): Observable<Chat> {}

    @Get('getChatMessage', (msgId: string) => `/chats/message/${msgId}`, {
        desc: 'Получить сообщение из чата по msgId',
        res: {
            errorTitle: 'Не удалось получить сообщение чата',
            clazz: ChatMessage
        }
    }) // @ts-ignore
    getChatMessage(msgId: string): Observable<ChatMessage> {}

    @Post('setChatMessageRemider', msgId => `/chats/message/${msgId}/remind`, {
        desc: 'Устанавливает напоминание для сообщения чата',
        body: (msgId: string, dt: Date) => ({ dt: dt.toISOString() }),
        res: {
            errorTitle: 'Не удалось установить напоминание о сообщении чата',
        }
    }) // @ts-ignore
    setChatMessageRemider(msgId: string, dt: Date): Observable<void> {}

    @Get('getChatMessages', (id: string, parentId?: string, start?: string, limit?: number) => id == 'mentions' ? '/chats/mentions' : `/chats/${id}/messages`, {
        desc: 'Получить сообщения чата или список напоминаний',
        params: (id: string, parentId?: string, start?: string, limit?: number) => {
            const httpParams: SM<string> | undefined = parentId || start ? {} : undefined;
            if (httpParams && parentId) {
                httpParams.parentId = parentId;
            }
            if (httpParams && start) {
                httpParams.start = start;
                if (limit != undefined) {
                    httpParams.limit = '' + limit;
                }
            }
            return httpParams;
        },
        res: {
            errorTitle: id => id == 'mentions' ? 'Не удалось получить список упоминаний' : 'Не удалось получить сообщения чата',
            parser: emitArray(ChatMessage)
        }
    }) // @ts-ignore
    getChatMessages(id: string, parentId?: string, start?: string, limit?: number): Observable<ChatMessage[]> {}

    @Get('getChatMembers', (id: Guid) => `/chats/${id}/members`, {
        desc: 'Получить список участников чата',
        res: {
            errorTitle: 'Не удалось получить список участников чата',
            parser: emitArray(Person)
        }
    }) // @ts-ignore
    getChatMembers(id: Guid): Observable<Person[]> {}

    @Post('joinChat', (id: Guid) => `/chats/${id}/members`, {
        desc: 'Пригласить / присоединиться к чату',
        body: [],
        res: {
            errorTitle: 'Не удалось присоединиться к чату'
        }
    }) // @ts-ignore
    joinChat(id: Guid): Observable<void> {}

    @Delete('leaveChat', (id: string) => `/chats/${id}/members`, {
        desc: 'Покинуть чат',
        res: {
            errorTitle: 'Не удалось покинуть чат',
        }
    }) // @ts-ignore
    leaveChat(id: string): Observable<void> {}

    @Post('inviteChatMembers', id => `/chats/${id}/members`, {
        desc: 'Пригласить людей в чат',
        body: (id: string, memberIds: string[]) => memberIds,
        res: {
            errorTitle: 'Не удалось пригласить новых участников в чат',
        }
    }) // @ts-ignore
    inviteChatMembers(id: string, memberIds: string[]): Observable<void> {}

    @Delete('kickChatMember', (chatId: string, memberId: string) => `/chats/${chatId}/members/${memberId}`, {
        desc: 'Исключает человека из группового чата',
        res: {
            errorTitle: 'Ошибка исключения участника чата',
            errors: {
                403: 'У вас нет прав для исключения участника из чата',
                404: 'Участник и/или чат не найден(ы)',
                409: 'Конфликт данных',
            }
        }
    }) // @ts-ignore
    kickChatMember(chatId: string, memberId: string): Observable<void> {}

    @Post('sendChatMessage', (chatId: string, msg: ChatMessage) => `/chats/${chatId}`, {
        desc: 'Отправить сообщение в чат',
        body: (chatId: string, msg: ChatMessage) => msg,
        res: {
            errorTitle: 'Не удалось отправить сообщение в чат',
            clazz: ChatMessage,
            errors: {
                413: 'Слишком большой размер сообщения и/или сниппетов'
            }
        },
        bodySizeLimit: 512 * 1024
    }) // @ts-ignore
    sendChatMessage(chatId: string, msg: ChatMessage): Observable<ChatMessage | undefined> {}

    @Put('editChatMessage', (chatId: string, msgId: string, msg: ChatMessage) => `/chats/${chatId}/${msgId}`, {
        desc: 'Отредактировать сообщение в чате',
        body: (chatId: string, msgId: string, msg: ChatMessage) => msg,
        res: {
            errorTitle: 'Не удалось отредактировать сообщение',
            clazz: ChatMessage,
            errors: {
                413: 'Слишком большой размер сообщения и/или сниппетов'
            }
        },
        bodySizeLimit: 512 * 1024
    }) // @ts-ignore
    editChatMessage(chatId: string, msgId: string, msg: ChatMessage): Observable<ChatMessage | undefined> {}

    @Post('deleteChatMessages', (chatId: string, ids: string[]) => `/chats/${chatId}/messages/delete`, {
        desc: 'Удалить сообщения в чате',
        body: (chatId: string, ids: string[]) => ids,
        res: {
            errorTitle: 'Не удалось удалить сообщения в чате',
        }
    }) // @ts-ignore
    deleteChatMessages(chatId: string, ids: string[]): Observable<void> {}

    @Post('videoInvite', (chatId: string, uid: string, dt: string) => `/vizorro/chat/${chatId}/invite/${uid}`, {
        desc: 'Отправить приглашение на видеопоказ',
        body: (chatId: string, uid: string, dt: string) => ({ dt }),
        res: {
            errorTitle: 'Не удалось отправить приглашение на видеопоказ',
        }
    }) // @ts-ignore
    videoInvite(chatId: string, uid: string, dt: string): Observable<void> {}

    @Post('videoStart', (chatId: string) => `/chats/${chatId}/video`, {
        desc: 'Создать комнату для видеоконференции',
        res: {
            errorTitle: 'Не удалось создать комнату для видеоконференции',
            clazz: ChatCall
        }
    }) // @ts-ignore
    videoStart(chatId: string): Observable<ChatCall> {}

    @Delete('videoFinish', (chatId: string) => `/chats/${chatId}/video`, {
        desc: 'Удалить комнату для видеоконференции',
        res: {
            errorTitle: 'Не удалось удалить комнату для видеоконференции',
        }
    }) // @ts-ignore
    videoFinish(chatId: string): Observable<void> {}

    @Put('readChat', (id: string, readDt: Date, threadId?: string) => `/chats/${id}/read`, {
        desc: 'Пометить чат как прочитанный',
        body: (id: string, readDt: Date, threadId?: string) => ({ dt: readDt.toISOString(), parentId: threadId }),
        res: {
            errorTitle: 'Не удалось пометить чат как прочитанный',
        }
    }) // @ts-ignore
    readChat(id: string, readDt: Date, threadId?: string): Observable<void> {}

    @Put('addChatMsgReaction', (chatId: string, msgId: string, reactionId: string) => `/chats/${chatId}/messages/${msgId}/reactions/${reactionId}`, {
        desc: 'Добавить реакцию к сообщению чата',
        res: {
            errorTitle: 'Не удалось добавить реакцию к сообщению чата',
        }
    }) // @ts-ignore
    addChatMsgReaction(chatId: string, msgId: string, reactionId: string): Observable<void> {}

    @Delete('removeChatMsgReaction', (chatId: string, msgId: string, reactionId: string) => `/chats/${chatId}/messages/${msgId}/reactions/${reactionId}`, {
        desc: 'Удалить реакцию на сообщение чата',
        res: {
            errorTitle: 'Не удалось удалить реакцию на сообщение чата',
        }
    }) // @ts-ignore
    removeChatMsgReaction(chatId: string, msgId: string, reactionId: string): Observable<void> {}

    @Get('getChatMentionsCounters', '/chats/mentions/counters', {
        desc: 'Получить счетчики упоминаний',
        params: (orgId: Guid) => ({ orgId }),
        res: {
            errorTitle: 'Не удалось получить счетчики упоминаний',
            clazz: MentionsCounters
        }
    }) // @ts-ignore
    getChatMentionsCounters(orgId: Guid): Observable<MentionsCounters> {}

    @Get('getChatMentions', '/chats/mentions', {
        desc: 'Получить список упоминаний',
        params: (orgId: Guid, start?: string, limit?: number) => {
            const httpParams: SM<string> = { orgId };
            if (start) {
                httpParams.start = start;
                if (limit != undefined) {
                    httpParams.limit = '' + limit;
                }
            }
            return httpParams;
        },
        res: {
            errorTitle: 'Не удалось получить список упоминаний',
            parser: emitArray(ChatMentionMessage)
        }
    }) // @ts-ignore
    getChatMentions(orgId: Guid, start?: string, limit?: number): Observable<ChatMentionMessage[]> {}

    @Put('readMentions', '/chats/mentions/read', {
        desc: 'Пометить упоминания как прочитанные',
        params: (orgId: Guid) => ({ orgId }),
        body: {},
        res: {
            errorTitle: 'Не удалось пометить упоминания как прочитанные',
            clazz: MentionsCounters
        }
    }) // @ts-ignore
    readMentions(orgId: Guid): Observable<void> {}

    @Post('getUrlPreview', '/chats/url_preview', {
        desc: 'Получить данные о гиперссылке',
        body: (url: string) => ({ url }),
        res: {
            errorTitle: 'Не удалось данные о гиперссылке',
            clazz: UrlPreview
        }
    }) // @ts-ignore
    getUrlPreview(url: string): Observable<UrlPreview> {}

    @Put('muteChat', (chatId: string, mute: boolean) => `/chats/${chatId}/${mute ? 'mute' : 'unmute'}`, {
        desc: 'Включить/выключить уведомления о сообщениях в чате',
        body: (url: string) => ({ url }),
        res: {
            errorTitle: (chatId: string, mute: boolean) => `Не удалось ${mute ? 'выключить' : 'включить'} уведомления в чате`,
        }
    }) // @ts-ignore
    muteChat(chatId: string, mute: boolean): Observable<void> {}

    @Post('searchChats', '/chats/search', {
        desc: 'Получить данные о гиперссылке',
        body: (orgId: Guid, text: string, chatId?: string, start?: number, limit?: number) => {
            const body: any = { orgId, text };
            if (start) {
                body.start = start;
            }
            if (limit) {
                body.limit = limit;
            }
            if (chatId) {
                body.chatId = chatId;
            }
            return body;
        },
        res: {
            errorTitle: 'Не удалось выполнить поиск в чате(ах)',
            parser: emitArray(ChatMessage)
        }
    }) // @ts-ignore
    searchChats(orgId: Guid, text: string, chatId?: string, start?: number, limit?: number): Observable<ChatMessage[]> {}

    @Post('getChatsStats', '/chats/list/stats', {
        desc: 'Получить статистику по чатам',
        body: (orgId: Guid) => ({ orgId }),
        res: {
            errorTitle: 'Не удалось получить статистику по чатам',
            parser: emitArray(ChatStats)
        }
    }) // @ts-ignore
    getChatsStats(orgId: Guid): Observable<ChatStats[] | undefined> {}

    @Post('generateChatInvitation', (chatId: Guid, invitation?: ChatInvitation) => `/chats/${chatId}/invitations`, {
        desc: 'Сгенерировать новое приглашение в чат',
        body: (chatId: Guid, invitation?: ChatInvitation) => invitation,
        res: {
            errorTitle: 'Не удалось сгенерировать новое приглашение в чат',
            parser: emitField('invitationId')
        }
    }) // @ts-ignore
    generateChatInvitation(chatId: Guid, invitation?: ChatInvitation): Observable<Guid> {}

    @Get('getChatInvitations', (chatId: Guid) => `/chats/${chatId}/invitations`, {
        desc: 'Получить список приглашений в чат',
        res: {
            errorTitle: all => 'Не удалось получить список приглашений в чат',
            parser: emitArray(ChatInvitation)
        }
    }) // @ts-ignore
    getChatInvitations(chatId: Guid): Observable<ChatInvitation[] | undefined> {}

    @Delete('deleteChatInvitations', (chatId: Guid, ids: Guid[]) => `/chats/${chatId}/invitations`, {
        desc: 'Удалить приглашения в чат',
        body: (chatId: Guid, ids: Guid[]) => ids,
        res: {
            errorTitle: 'Не удалось приглашения в чат',
        }
    }) // @ts-ignore
    deleteChatInvitations(chatId: Guid, ids: Guid[]): Observable<void> {}

    @Post('guestSignin', '/chats/guest', {
        desc: 'Войти в систему как гость и получить авторизационные токены и ID чата',
        body: (invitationId: Guid) => ({ invitationId }),
        res: {
            errorTitle: 'Не удалось войти в систему',
            clazz: SigninResponse
        }
    }) // @ts-ignore
    guestSignin(invitationId: Guid): Observable<SigninResponse> {}


    // * ######################################################################################################### Files
    @Post('createFile', '/files', {
        desc: 'Создать запись о файле в БД',
        body: (file: IVzFileTpl) => file,
        res: {
            errorTitle: 'Не удалось создать файл',
            parser: emitField('id'),
        },
        bypassSW: true
    }) // @ts-ignore
    createFile(file: IVzFileTpl): Observable<string> {}

    @Post('uploadFile', (id: string, file: File, callbacks?: RequestCallbacks) => `/files/${id}`, {
        desc: 'Загрузить на сервер содержимое файла',
        body: (id: string, file: File, callbacks?: RequestCallbacks) => {
            const body = new FormData();
            body.append('file', file, id);
            return body;
        },
        callbacks: (id: string, file: File, callbacks?: RequestCallbacks) => callbacks,
        res: {
            errorTitle: 'Не удалось загрузить на сервер содержимое файла',
        },
        bypassSW: true,
        bodySizeLimit: -1
    }) // @ts-ignore
    uploadFile(id: string, file: File, options?: RequestCallbacks): Observable<void> {}

    @Post('getFiles', '/files/list', {
        desc: 'Получить информацию о файлах',
        body: (ids: string[]) => ids,
        res: {
            errorTitle: 'Не удалось получить информацию о файлах',
            parser: emitArray(VzFile),
        },
    }) // @ts-ignore
    getFiles(ids: string[]): Observable<VzFile[] | undefined> {}

    @Get('getFile', (id: string) => `/files/${id}/info`, {
        desc: 'Получить информацию о файле',
        res: {
            errorTitle: 'Не удалось получить информацию о файле',
            clazz: VzFile
        }
    }) // @ts-ignore
    getFile(id: string): Observable<VzFile> {}

    @Delete('deleteFile', (id: string) => `/files/${id}`, {
        desc: 'Удалить файл',
        res: {
            errorTitle: 'Не удалось удалить файл',
        }
    }) // @ts-ignore
    deleteFile(id: string): Observable<void> {}

    @Get('getFileContent', (id: string, preview?: 'img' | 'pdf') => `/files/${id}`, {
        desc: 'Получить содержимое файла',
        params: (id: string, preview?: 'img' | 'pdf') => preview ? { preview } : undefined,
        res: {
            errorTitle: 'Не удалось получить содержимое файла',
            type: 'blob'
        }
    }) // @ts-ignore
    getFileContent(id: string, preview?: 'img' | 'pdf'): Observable<Blob> {}

    @Get('getFileDownloadLink', (id: string) => `/files/${id}/link`, {
        desc: 'Получить ссылку для скачивания файла',
        res: {
            errorTitle: 'Не удалось получить ссылку для скачивания файла',
            parser: emitField('url')
        }
    }) // @ts-ignore
    getFileDownloadLink(id: string): Observable<string> {}

    @Get('getOrgFilesStats', (orgId: Guid) => `/files/orgstats/${orgId}`, {
        desc: 'Получить статистику о файлах организации',
        res: {
            errorTitle: 'Не удалось получить статистику о файлах организации',
            clazz: VzFilesOrgStats
        }
    }) // @ts-ignore
    getOrgFilesStats(orgId: Guid): Observable<VzFilesOrgStats> {}


    // * ######################################################################################################### Push (notifications, telegram, fcm)
    @Get('getNotifications', '/push/notifications', {
        desc: 'Получить список уведомлений',
        params: (filter: 'seen' | 'unseen' | 'all' = 'all') => ({ filter }),
        res: {
            errorTitle: 'Не удалось получить список уведомлений',
            parser: emitArray(UserNotification)
        }
    }) // @ts-ignore
    getNotifications(filter: 'seen' | 'unseen' | 'all' = 'all'): Observable<UserNotification[] | undefined> {}

    @Put('markAsSeenNotifications', '/push/notifications', {
        desc: 'Отметить уведомления как прочитанные',
        body: (ids: string[]) => ids,
        res: {
            errorTitle: 'Не удалось отметить уведомления как прочитанные',
        }
    }) // @ts-ignore
    markAsSeenNotifications(ids: string[]): Observable<void> {}

    @Delete('deleteNotifications', '/push/notifications', {
        desc: 'Удалить уведомления',
        body: (ids: string[]) => ids,
        res: {
            errorTitle: 'Не удалось удалить уведомления',
        }
    }) // @ts-ignore
    deleteNotifications(ids: string[]): Observable<void> {}

    @Get('getTelegramInvite', () => `/push/telegram`, {
        desc: 'Получить URL для подключения Telegram уведомлений',
        res: {
            errorTitle: 'Не удалось получить URL для подключения Telegram уведомлений',
            parser: emitField('invitationURL', true)
        }
    }) // @ts-ignore
    getTelegramInvite(): Observable<string | undefined> {}

    @Delete('disconnectTelegram', () => `/push/telegram`, {
        desc: 'Отключить Telegram уведомления',
        res: {
            errorTitle: 'Не удалось отключить Telegram уведомления',
        }
    }) // @ts-ignore
    disconnectTelegram(): Observable<void> {}

    @Post('connectFcm', '/push/fcm/subscribe', {
        desc: 'Подключить Push-уведомления',
        body: (token: string, type: string,  deviceInfo: DeviceInfo) => ({ token, type, deviceInfo }),
        res: {
            errorTitle: 'Не удалось подключить Push-уведомления',
        }
    }) // @ts-ignore
    connectFcm(token: string, type: string,  deviceInfo: DeviceInfo): Observable<void> {}

    @Post('disconnectFcm', '/push/fcm/unsubscribe', {
        desc: 'Отключить Push-уведомления',
        body: (token: string) => ({ token, type: 'web' }),
        res: {
            errorTitle: 'Не удалось отключить Push-уведомления',
        }
    }) // @ts-ignore
    disconnectFcm(token: string): Observable<void> {}

    @Get('getFcmSubscriptions', () => `/push/fcm/subscriptions`, {
        desc: 'Получить список устройств подписанных на Push-уведомления',
        res: {
            errorTitle: 'Не удалось получить список устройств подписанных на Push-уведомления',
            parser: emitArray(FcmSubscription)
        }
    }) // @ts-ignore
    getFcmSubscriptions(): Observable<FcmSubscription[] | undefined> {}

    @Post('testFcm', '/push/fcm/test', {
        desc: 'Отправить тестовое Push-уведомление',
        body: (title: string, body: string, data: any, url?: string) => ({ title, body, data, url }),
        res: {
            errorTitle: 'Не удалось отправить тестовое Push-уведомление',
        }
    }) // @ts-ignore
    testFcm(title: string, body: string, data: any, url?: string): Observable<void> {}

    @Get('getNotificationSettings', () => `/push/settings`, {
        desc: 'Получить настройки сервиса уведомлений',
        res: {
            errorTitle: 'Не удалось получить настройки сервиса уведомлений',
            parser: emitArray(UserNotificationSettings)
        }
    }) // @ts-ignore
    getNotificationSettings(): Observable<UserNotificationSettings[] | undefined> {}

    @Put('setNotificationSettings', '/push/settings', {
        desc: 'Сохранить настройки сервиса уведомлений',
        body: (settings: UserNotificationSettings[]) => settings,
        res: {
            errorTitle: 'Не удалось сохранить настройки сервиса уведомлений',
        }
    }) // @ts-ignore
    setNotificationSettings(settings: UserNotificationSettings[]): Observable<void> {}


    // * ######################################################################################################### Track Service
    @Post('getCalendarEvents', '/events/list', {
        desc: 'Получить список календарных событий',
        body: (since: Date, till: Date) => ({ since: since.toISOString(), till: till.toISOString() }),
        res: {
            errorTitle: 'Не удалось получить список календарных событий',
            parser: emitArray(CalendarEvent)
        }
    }) // @ts-ignore
    getCalendarEvents(since: Date, till: Date): Observable<CalendarEvent[] | undefined> {}

    @Get('getCalendarEvent', (id: string) => `/events/${id}`, {
        desc: 'Получить календарное событие',
        res: {
            errorTitle: 'Не удалось получить календарное событие',
            clazz: CalendarEvent
        }
    }) // @ts-ignore
    getCalendarEvent(id: string): Observable<CalendarEvent | undefined> {}

    @Put('updateCalendarEvent', (id: string, event: CalendarEvent) => `/events/${id}`, {
        desc: 'Изменить календарное событие',
        body: (id: string, event: CalendarEvent) => event,
        res: {
            errorTitle: 'Не удалось изменить календарное событие',
            clazz: CalendarEvent
        }
    }) // @ts-ignore
    updateCalendarEvent(id: string, event: CalendarEvent): Observable<CalendarEvent> {}

    @Put('setEventAttendeeState', (id: string, state: EventAttendeeState) => `/events/${id}/state`, {
        desc: 'Изменить статуса участника календарного события',
        body: (id: string, state: EventAttendeeState) => ({ state }),
        res: {
            errorTitle: 'Не удалось изменить статуса участника календарного события',
        }
    }) // @ts-ignore
    setEventAttendeeState(id: string, state: EventAttendeeState): Observable<void> {}

    @Delete('deleteCalendarEvent', (id: string) => `/events/${id}`, {
        desc: 'Удалить календарное событие',
        res: {
            errorTitle: 'Не удалось удалить календарное событие',
        }
    }) // @ts-ignore
    deleteCalendarEvent(id: string): Observable<void> {}

    @Post('createCalendarEvent', '/events', {
        desc: 'Создать календарное событие',
        body: (event: CalendarEvent) => event,
        res: {
            errorTitle: 'Не удалось создать календарное событие',
            clazz: CalendarEvent
        }
    }) // @ts-ignore
    createCalendarEvent(event: CalendarEvent): Observable<CalendarEvent> {}

    @Get('getClosestCalendarEvents', (days: number) => `/events/stats/closest/${days}`, {
        desc: 'Получить список ближайших календарных событий',
        res: {
            errorTitle: 'Не удалось получить список ближайших календарных событий',
            parser: emitArray(CalendarEvent)
        }
    }) // @ts-ignore
    getClosestCalendarEvents(days: number): Observable<CalendarEvent[]> {}


    // * ######################################################################################################### User-Org status
    @Get('getUserOrgStatusTypes', () => `/org_status/types`, {
        desc: 'Получить список статусов для организации',
        res: {
            errorTitle: 'Не удалось получить список статусов для организации',
            parser: emitArray(UserOrgStatusType)
        }
    }) // @ts-ignore
    getUserOrgStatusTypes(): Observable<UserOrgStatusType[] | undefined> {}

    @Post('createUserOrgStatusType', '/org_status/types', {
        desc: 'Создать статус для организации',
        body: (status: IUserOrgStatusTypeTpl) => status,
        res: {
            errorTitle: 'Не удалось создать статус для организации',
            clazz: UserOrgStatusType
        }
    }) // @ts-ignore
    createUserOrgStatusType(status: IUserOrgStatusTypeTpl): Observable<UserOrgStatusType> {}

    @Get('getUserOrgStatusType', (id: string) => `/org_status/types/${id}`, {
        desc: 'Получить статус для организации',
        res: {
            errorTitle: 'Не удалось получить статус для организации',
            clazz: UserOrgStatusType
        }
    }) // @ts-ignore
    getUserOrgStatusType(id: string): Observable<UserOrgStatusType> {}

    @Put('updateUserOrgStatusType', (id: string, status: IUserOrgStatusTypeTpl) => `/org_status/types/${id}`, {
        desc: 'Изменить статус для организации',
        body: (id: string, status: IUserOrgStatusTypeTpl) => status,
        res: {
            errorTitle: 'Не удалось изменить статус для организации',
            clazz: UserOrgStatusType
        }
    }) // @ts-ignore
    updateUserOrgStatusType(id: string, status: IUserOrgStatusTypeTpl): Observable<UserOrgStatusType> {}

    @Delete('deleteUserOrgStatusType', (id: string) => `/org_status/types/${id}`, {
        desc: 'Удалить статус для организации',
        res: {
            errorTitle: 'Не удалось удалить статус для организации',
        }
    }) // @ts-ignore
    deleteUserOrgStatusType(id: string): Observable<void> {}

    @Get('getUserStatuses', () => `/org_status`, {
        desc: 'Получить список статусов сотрудников',
        res: {
            errorTitle: 'Не удалось получить список статусов сотрудников',
            parser: emitArray(UserOrgStatus)
        }
    }) // @ts-ignore
    getUserStatuses(): Observable<UserOrgStatus[] | undefined> {}

    @Get('getUserOrgStatuses', (orgId: Guid) => `/org_status/${orgId}`, {
        desc: 'Получить список статусов сотрудников для организации',
        res: {
            errorTitle: 'Не удалось получить список статусов сотрудников',
            parser: emitArray(UserOrgStatus)
        }
    }) // @ts-ignore
    getUserOrgStatuses(orgId: Guid): Observable<UserOrgStatus[] | undefined> {}

    @Get('getUserOrgStatus', (orgId: Guid, userId: Guid) => `/org_status/${orgId}/user/${userId}`, {
        desc: 'Получить статус сотрудника в организации',
        res: {
            errorTitle: 'Не удалось получить статус сотрудника в организации',
            clazz: UserOrgStatus
        }
    }) // @ts-ignore
    getUserOrgStatus(orgId: Guid, userId: Guid): Observable<UserOrgStatus> {}

    @Put('setUserOrgStatus', (orgId: Guid, userId: Guid, statusId: string) => `/org_status/${orgId}/user/${userId}`, {
        desc: 'Получить статус сотрудника в организации',
        body: (orgId: Guid, userId: Guid, statusId: string) => ({ statusId }),
        res: {
            errorTitle: 'Не удалось получить статус сотрудника в организации',
            clazz: UserOrgStatus
        }
    }) // @ts-ignore
    setUserOrgStatus(orgId: Guid, userId: Guid, statusId: string): Observable<UserOrgStatus> {}

    @Get('getUserOrgStatusLog', (orgId: Guid, userId: Guid) => `/org_status/${orgId}/user/${userId}/log`, {
        desc: 'Получить журнал изменения статусов сотрудника в организации',
        res: {
            errorTitle: 'Не удалось получить журнал изменения статусов сотрудника в организации',
            parser: emitArray(UserOrgStatus)
        }
    }) // @ts-ignore
    getUserOrgStatusLog(orgId: Guid, userId: Guid): Observable<UserOrgStatus[] | undefined> {}


    // * ######################################################################################################### Billing
    @Get('getOrgBillingInfo', (orgId: Guid) => `/billing/org/${orgId}`, {
        desc: 'Получить платежную информацию организации',
        res: {
            errorTitle: 'Не удалось получить платежную информацию организации',
            clazz: OrgInfo
        }
    }) // @ts-ignore
    getOrgBillingInfo(orgId: Guid): Observable<OrgInfo> {}

    @Put('setOrgBillingInfo', (orgId: Guid, info: IOrgInfo) => `/billing/org/${orgId}`, {
        desc: 'Изменить платежную информацию организации',
        body: (orgId: Guid, info: IOrgInfo) => info,
        res: {
            errorTitle: 'Не удалось изменить платежную информацию организации',
        }
    }) // @ts-ignore
    setOrgBillingInfo(orgId: Guid, info: IOrgInfo): Observable<void> {}

    @Put('setOrgBillingSlots', (orgId: Guid, slotsNew: number) => `/billing/org/${orgId}/slots`, {
        desc: 'Изменить количество рабочих мест в организации',
        body: (orgId: Guid, slotsNew: number) => ({ slotsNew }),
        res: {
            errorTitle: 'Не удалось изменить количество рабочих мест в организации',
        }
    }) // @ts-ignore
    setOrgBillingSlots(orgId: Guid, slotsNew: number): Observable<void> {}

    @Put('setOrgPayPlan', (orgId: Guid, payPlan: OrgPayPlan) => `/billing/org/${orgId}/payplan`, {
        desc: 'Изменить тарифный план организации',
        body: (orgId: Guid, payPlan: OrgPayPlan) => ({ payPlan }),
        res: {
            errorTitle: 'Не удалось изменить тарифный план организации',
        }
    }) // @ts-ignore
    setOrgPayPlan(orgId: Guid, payPlan: OrgPayPlan): Observable<void> {}

    @Post('createOrgInvoice', (orgId: Guid, sum: number) => `/billing/org/${orgId}/invoice`, {
        desc: 'Создать новый счет для оплаты',
        body: (orgId: Guid, sum: number) => ({ sum }),
        res: {
            errorTitle: 'Не удалось создать счет для оплаты',
            clazz: OrgInvoice
        }
    }) // @ts-ignore
    createOrgInvoice(orgId: Guid, sum: number): Observable<OrgInvoice> {}

    @Get('getOrgInvoices', (orgId: Guid) => `/billing/org/${orgId}/invoice`, {
        desc: 'Получить список счетов для оплаты',
        res: {
            errorTitle: 'Не удалось получить список счетов для оплаты',
            parser: emitArray(OrgInvoice)
        }
    }) // @ts-ignore
    getOrgInvoices(orgId: Guid): Observable<OrgInvoice[]> {}

    @Get('getOrgInvoice', (orgId: Guid, id: string) => `/billing/org/${orgId}/invoice/${id}`, {
        desc: 'Получить счет для оплаты по номеру',
        res: {
            errorTitle: (orgId: Guid, id: string) => `Не удалось получить счет № ${id}`,
            clazz: OrgInvoice
        }
    }) // @ts-ignore
    getOrgInvoice(orgId: Guid, id: string): Observable<OrgInvoice> {}

    @Get('getOrgBalanceChanges', (orgId: Guid) => `/billing/org/${orgId}/balance_changes`, {
        desc: 'Получить историю изменения баланса организации',
        res: {
            errorTitle: 'Не удалось получить историю изменения баланса организации',
            parser: emitArray(OrgBalanceChange)
        }
    }) // @ts-ignore
    getOrgBalanceChanges(orgId: Guid): Observable<OrgBalanceChange[]> {}

    @Post('addToVideoBalance', (orgId: Guid, sum: number) => `/billing/org/${orgId}/balance_changes`, {
        desc: 'Перевести средства на баланс для видео',
        body: (orgId: Guid, sum: number) => ({ balanceVideo: sum }),
        res: {
            errorTitle: 'Не удалось перевести средства на баланс для видео',
        }
    }) // @ts-ignore
    addToVideoBalance(orgId: Guid, sum: number): Observable<void> {}

    @Get('getPaidCalls', (orgId: Guid) => `/billing/org/${orgId}/paid_calls`, {
        desc: 'Получить список платных звонков',
        res: {
            errorTitle: 'Не удалось получить список платных звонков',
            parser: emitArray(OrgPaidCall)
        }
    }) // @ts-ignore
    getPaidCalls(orgId: Guid): Observable<OrgPaidCall[]> {}

    @Get('getTariffs', '/billing/tariffs', {
        desc: 'Получить список ограничений по тарифам',
        res: {
            errorTitle: 'Не удалось получить список ограничений по тарифам',
            parser: emitArray(VzTariff)
        }
    }) // @ts-ignore
    getTariffs(): Observable<VzTariff[]> {}


    // * ######################################################################################################### Tags

    @Post('createTag', '/tags', {
        desc: 'Создать тег',
        body: (tag: VzTag) => tag,
        res: {
            errorTitle: 'Не удалось создать тег',
            clazz: VzTag
        }
    }) // @ts-ignore
    createTag(tag: VzTag): Observable<VzTag> {}

    @Put('updateTag', (id: string, tag: VzTag) => `/tags/${id}`, {
        desc: 'Изменить тег',
        body: (id: string, tag: VzTag) => tag,
        res: {
            errorTitle: 'Не удалось изменить тег',
            clazz: VzTag
        }
    }) // @ts-ignore
    updateTag(id: string, tag: VzTag): Observable<VzTag> {}

    @Delete('deleteTag', (id: string) => `/tags/${id}`, {
        desc: 'Удалить тег',
        res: {
            errorTitle: 'Не удалось удалить тег',
        }
    }) // @ts-ignore
    deleteTag(id: string): Observable<void> {}

    @Get('getTags', () => `/tags`, {
        desc: 'Получить список тегов',
        res: {
            errorTitle: 'Не удалось получить список тегов',
            parser: emitArray(VzTag)
        }
    }) // @ts-ignore
    getTags(): Observable<VzTag[]> {}

    @Get('getTag', (id: string) => `/tags/${id}`, {
        desc: 'Получить тег',
        res: {
            errorTitle: 'Не удалось получить тег',
            clazz: VzTag
        }
    }) // @ts-ignore
    getTag(id: string): Observable<VzTag> {}


    // * ######################################################################################################### Integrations

    @Get('getIntegrations', '/integrations', {
        desc: 'Получить список доступных для подключения интеграций',
        res: {
            errorTitle: 'Не удалось получить список доступных для подключения интеграций',
            parser: emitArray(Integration)
        }
    }) // @ts-ignore
    getIntegrations(): Observable<Integration[]> {}

    @Get('getIntegration', (id: Guid) => `/integrations/${id}`, {
        desc: 'Получить интеграцию по ИД',
        res: {
            errorTitle: 'Не удалось получить интеграцию',
            clazz: Integration
        }
    }) // @ts-ignore
    getIntegration(id: Guid): Observable<Integration> {}

    @Get('getIntegrationSetups', () => `/integrations/setup`, {
        desc: 'Получить список всех подключенных интеграций',
        res: {
            errorTitle: 'Не удалось получить список всех подключенных интеграций',
            parser: emitArray(IntegrationSetup)
        }
    }) // @ts-ignore
    getIntegrationSetups(): Observable<IntegrationSetup[]> {}

    @Get('getIntegrationSetup', (id: Guid) => `/integrations/setup/${id}`, {
        desc: 'Получить подключенную интеграцию по ИД подключения',
        res: {
            errorTitle: 'Не удалось получить подключенную интеграцию',
            clazz: IntegrationSetup
        }
    }) // @ts-ignore
    getIntegrationSetup(id: Guid): Observable<IntegrationSetup> {}

    @Post('createIntegrationSetup', (id: Guid, setup: IntegrationSetup) => `/integrations/${id}/setup`, {
        desc: 'Подключить интеграцию',
        body: (id: Guid, setup: IntegrationSetup) => setup,
        res: {
            errorTitle: 'Не удалось подключить интеграцию',
        }
    }) // @ts-ignore
    createIntegrationSetup(id: Guid, setup: IntegrationSetup): Observable<void> {}

    @Put('updateIntegrationSetup', (id: Guid, setup: IntegrationSetup) => `/integrations/setup/${id}`, {
        desc: 'Измененить настройки подключения интеграции',
        body: (id: Guid, setup: IntegrationSetup) => setup,
        res: {
            errorTitle: 'Не удалось измененить настройки подключения интеграции',
        }
    }) // @ts-ignore
    updateIntegrationSetup(id: Guid, setup: IntegrationSetup): Observable<void> {}

    @Delete('deleteIntegrationSetup', (id: Guid) => `/integrations/setup/${id}`, {
        desc: 'Удалить подключение интеграции по ИД',
        res: {
            errorTitle: 'Не удалось удалить подключение интеграции',
        }
    }) // @ts-ignore
    deleteIntegrationSetup(id: Guid): Observable<void> {}

    @Get('getIntegrationTriggers', (setupId: Guid) => `/integrations/setup/${setupId}/triggers`, {
        desc: 'Получить список триггеров подключенной интеграции по ИД подключения',
        res: {
            errorTitle: 'Не удалось получить список триггеров подключенной интеграции',
            parser: emitArray(IntegrationTrigger)
        }
    }) // @ts-ignore
    getIntegrationTriggers(setupId: Guid): Observable<IntegrationTrigger[]> {}

    @Post('createIntegrationTrigger', (setupId: Guid, trigger: IntegrationTrigger) => `/integrations/setup/${setupId}/triggers`, {
        desc: 'Создать новый триггер для подключенной интеграции',
        body: (id: Guid, trigger: IntegrationTrigger) => trigger,
        res: {
            errorTitle: 'Не удалось создать новый триггер для подключенной интеграции',
        }
    }) // @ts-ignore
    createIntegrationTrigger(setupId: Guid, trigger: IntegrationTrigger): Observable<void> {}

    @Put('updateIntegrationTrigger', (id: Guid, trigger: IntegrationTrigger) => `/integrations/triggers/${id}`, {
        desc: 'Измененить триггер для подключенной интеграции',
        body: (id: Guid, trigger: IntegrationTrigger) => trigger,
        res: {
            errorTitle: 'Не удалось измененить триггер для подключенной интеграции',
        }
    }) // @ts-ignore
    updateIntegrationTrigger(id: Guid, trigger: IntegrationTrigger): Observable<void> {}

    @Delete('deleteIntegrationTrigger', (id: Guid) => `/integrations/triggers/${id}`, {
        desc: 'Удалить триггер для подключенной интеграции по ИД',
        res: {
            errorTitle: 'Не удалось удалить триггер для подключенной интеграции',
        }
    }) // @ts-ignore
    deleteIntegrationTrigger(id: Guid): Observable<void> {}

    @Get('getConnectedIntegrationTriggers', (type: VzSystemModule, id: Guid) => `/integrations/info/connected`, {
        desc: 'Получить список подключенных триггеров к объекту данного типа',
        params: (type: VzSystemModule, id: Guid) => ({ type, id }),
        res: {
            errorTitle: 'Не удалось получить список подключенных триггеров к объекту данного типа',
            parser: emitArray(IntegrationTrigger)
        }
    }) // @ts-ignore
    getConnectedIntegrationTriggers(type: VzSystemModule, id: Guid): Observable<IntegrationTrigger[]> {}

    @Get('getAvailableIntegrationSetups', (type: VzSystemModule, id: Guid) => `/integrations/info/available`, {
        desc: 'Получить список доступных для подключения настроенных интеграций к объекту данного типа',
        params: (type: VzSystemModule, id: Guid) => ({ type, id }),
        res: {
            errorTitle: 'Не удалось получить список доступных для подключения настроенных интеграций к объекту данного типа',
            parser: emitArray(IntegrationSetup)
        }
    }) // @ts-ignore
    getAvailableIntegrationSetups(type: VzSystemModule, id: Guid): Observable<IntegrationSetup[]> {}

    @Get('getIntegrationSetupEvents', (setupId: Guid, from?: Date, limit?: number) => `/integrations/setup/${setupId}/events`, {
        desc: 'Получить список событий для подключенной интеграции',
        params: (setupId: Guid, from?: Date, limit?: number) => getHttpParams({ from: from?.toISOString(), limit }),
        res: {
            errorTitle: 'Не удалось получить список событий для подключенной интеграции',
            parser: emitArray(IntegrationWebhookEvent)
        }
    }) // @ts-ignore
    getIntegrationSetupEvents(setupId: Guid, from?: Date, limit?: number): Observable<IntegrationWebhookEvent[]> {}

    @Get('getIntegrationSetupEvent', (id: Guid, setupId: Guid) => `/integrations/setup/${setupId}/events/${id}`, {
        desc: 'Получить событие для подключенной интеграции',
        res: {
            errorTitle: 'Не удалось получить событие для подключенной интеграции',
            clazz: IntegrationWebhookEvent
        }
    }) // @ts-ignore
    getIntegrationSetupEvent(id: Guid, setupId: Guid): Observable<IntegrationWebhookEvent> {}


    // * ######################################################################################################### Tips
    @Get('getNextTip', (numId: number) => `/events/tips/${numId}`, {
        desc: 'Получить следующий совет',
        res: {
            errorTitle: 'Не удалось получить следующий совет',
            parser: emitOptional(VzTip)
        }
    }) // @ts-ignore
    getNextTip(numId: number): Observable<VzTip | undefined> {}



    // * ######################################################################################################### Helpers
    @Get('getAssetFile',
        (fn: string, responseType: TResponseType, errText: string) => `./assets/${fn}`, {
        desc: 'Получить содержимое файла',
        cfg: 'app',
        res: {
            type: (fn: string, responseType: TResponseType, errText: string) => responseType,
            errorTitle: (fn: string, responseType: TResponseType, errText: string) => errText
        }
    }) // @ts-ignore
    getAssetFile(fn: string, responseType: TResponseType, errText: string): Observable<any> {}

}

export enum PrivilegeType {
    Person = 1,
    Plugin,
    All = 1000
}

export type PrivilegeTreeItem = {
    id: string;
    name: string;
    desc?: string;
    children?: PrivilegeTreeItem[];
    includes?: string[];
    fullId?: string;
    path?: string;
    danger?: boolean;
    global?: boolean;
    implies?: string[];
};

export const PrivilegesOrg: PrivilegeTreeItem = {
    id: 'org',
    name: 'Организация',
    global: true,
    danger: true,
    children: [
        {
            id: 'edit',
            name: 'Редактировать информацию об организации',
            danger: true,
            desc: 'Менять логотип организации и другую информацию'
        },
        {
            id: 'admin',
            name: 'Администрировать',
            // desc: 'Управлять различными объектами организации',
            danger: true,
            children: [
                {
                    id: 'groups',
                    name: 'Группы',
                    desc: 'Управлять группами пользователей, создавать, удалять, менять состав',
                },
                {
                    id: 'roles',
                    name: 'Роли',
                    desc: 'Управлять ролями, создавать, удалять, изменять набор привилегий входящих в роль, назначать их сотрудникам и группам',
                },
                {
                    id: 'projects',
                    name: 'Проекты',
                    children: [
                        {
                            id: 'create',
                            name: 'Создавать',
                            desc: 'Создавать новые проекты',
                        },
                        {
                            id: 'update',
                            name: 'Изменять',
                            desc: 'Редактировать любые проекты и их команду',
                        },
                        {
                            id: 'delete',
                            name: 'Удалять',
                            desc: 'Удалять любые проекты',
                            danger: true,
                        },
                    ]
                },
                {
                    id: 'team',
                    name: 'Сотрудников',
                    children: [
                        {
                            id: 'invite',
                            name: 'Приглашать в организацию',
                        },
                        // {
                        //     id: 'edit',
                        //     name: 'Редактировать информацию',
                        // },
                        {
                            id: 'disable',
                            name: 'Блокировать',
                        },
                        {
                            id: 'delete',
                            name: 'Удалять из организации',
                        },
                        {
                            id: 'status',
                            name: 'Менять статус',
                        },
                    ]
                },
                {
                    id: 'plugins',
                    name: 'Расширения',
                    desc: 'Подключать/отключать и управлять настройками дополнительных модулей системы',
                },
                {
                    id: 'status',
                    name: 'Статусы',
                    desc: 'Управлять списком доступных статусов для сотрудников в организации (название, значок, цвет)',
                },
                {
                    id: 'chats',
                    name: 'Чаты',
                },
                // {
                //     id: 'files',
                //     name: 'Файлы',
                //     desc: 'Удалаять любые файлы принадлежащие организации (не дает доступа к содержимому файлов)'
                // },
            ]
        },
        // {
        //     id: 'client',
        //     name: 'Клиенты',
        //     children: [
        //         {
        //             id: 'add',
        //             name: 'Добавлять',
        //         },
        //         {
        //             id: 'edit',
        //             name: 'Редактировать',
        //         },
        //         {
        //             id: 'delete',
        //             name: 'Удалять',
        //             danger: true,
        //         },
        //     ]
        // },
    ]
};

export const PrivilegesTask: PrivilegeTreeItem = {
    id: 'task',
    name: 'Задачи',
    children: [
        {
            id: 'create',
            name: 'Создавать',
            desc: 'Создавать новые задачи в проектах в которых вы участвуете',
        },
        {
            id: 'edit',
            name: 'Редактировать',
            desc: 'Редактировать текст задачи, тему, список наблюдателей и проверяющих, набор тегов и срок выполнения',
        },
        {
            id: 'close',
            name: 'Архивировать',
            desc: 'Отправлять закрытые задачи в архив',
        },
        {
            id: 'delete',
            name: 'Удалять',
            desc: 'Полностью удалять задачи',
            danger: true,
        },
        {
            id: 'assign',
            name: 'Назначать ответственного',
            desc: 'Назначать задачу на любого сотрудника (или группу) в пределах проекта/организации',
        },
        {
            id: 'note',
            name: 'Комментарии',
            children: [
                {
                    id: 'add',
                    name: 'Добавлять',
                },
                {
                    id: 'edit',
                    name: 'Редактировать (только свои)',
                },
                {
                    id: 'delete',
                    name: 'Удалять комментарии',
                    danger: true,
                },
            ]
        },
    ]
};

export const PrivilegesChat: PrivilegeTreeItem = {
    id: 'chat',
    name: 'Чаты',
    global: true,
    children: [
        {
            id: 'create',
            name: 'Создавать новые',
            desc: 'Создавать новые чаты выбрав участников из организации/проекта',
        },
        {
            id: 'invite',
            name: 'Приглашать новых участников',
            desc: 'Находясь в чате, приглашать (добавлять) в чат новых участников из организации/проекта',
        },
        {
            id: 'kick',
            name: 'Исключать участников',
            desc: 'Удалять участников из чата. Их сообщения останутся в истории чата, сами они перестанут получать сообщения из этого чата и не увидят его в списке своих чатов (если у них нет специальной привилегии)',
            danger: true,
        },
        {
            id: 'join',
            name: 'Подключаться без приглашения к приватным чатам',
            desc: 'Позволяет видеть в списке чатов все чаты для организации/проекта, позволяет подключиться (стать участником) или просмотреть историю любого чата',
            danger: true,
        },
    ]
};

export const PrivilegesFile: PrivilegeTreeItem = {
    id: 'file',
    name: 'Файлы',
    children: [
        {
            id: 'create',
            name: 'Загружать на сервер новые',
        },
        {
            id: 'write',
            name: 'Заменять уже загруженные новыми',
        },
        {
            id: 'read',
            name: 'Скачивать с сервера',
        },
        {
            id: 'delete',
            name: 'Удалять',
            danger: true,
        },
    ]
};

// export const PrivilegesProject: PrivilegeTreeItem = {
//     id: 'project',
//     name: 'Проекты',
//     children: [
//         // {
//         //     id: 'admin',
//         //     name: 'Администрировать все в проекте',
//         //     desc: 'Управлять командой проекта, его файлами и т.д.',
//         //     danger: true,
//         // },
//         // {
//         //     id: 'create',
//         //     name: 'Создавать',
//         //     desc: 'Создавать новые проекты',
//         // },
//         // {
//         //     id: 'delete',
//         //     name: 'Удалять',
//         //     desc: 'Безвозвратно удалять проекты',
//         //     danger: true,
//         // },
//         {
//             id: 'update',
//             name: 'Изменять настройки',
//             desc: 'Редактировать параметры проекта (кроме команды)',
//             danger: true,
//         },
//         {
//             id: 'members',
//             name: 'Управлять командой',
//             desc: 'Приглашать людей в команду и удалять людей из команды',
//             danger: true,
//         },
//     ]
// };

export const PrivilegesCalendar: PrivilegeTreeItem = {
    id: 'event',
    name: 'События',
    children: [
        {
            id: 'calendar',
            name: 'Календарные',
            children: [
                {
                    id: 'list',
                    name: 'Просматривать список',
                },
                {
                    id: 'create',
                    name: 'Создавать новые',
                },
                {
                    id: 'update',
                    name: 'Изменять существующие',
                },
                {
                    id: 'delete',
                    name: 'Удалять',
                    danger: true,
                },
            ]
        },
    ]
};

export const PrivilegesAuth: PrivilegeTreeItem = {
    id: 'auth',
    name: 'Ключи авторизации',
    danger: true,
    children: [
        {
            id: 'create',
            name: 'Создавать ключи',
        },
        {
            id: 'revoke',
            name: 'Отзывать ключи',
        },
    ]
};

export const PrivilegesAdmin: PrivilegeTreeItem = {
    id: 'admin',
    name: 'Администрирование системы Vizorro',
    danger: true,
    children: [
        {
            id: 'org',
            name: 'Организации',
            children: [
                {
                    id: 'info',
                    name: 'Просматривать организации',
                    desc: 'Позволяет просматривать информацию об организациях и их владельцах'
                },
                {
                    id: 'create',
                    name: 'Создавать организации',
                    desc: 'Позволяет добавлять в систему новые организации к существующим учетным записям пользователей'
                },
                {
                    id: 'edit',
                    name: 'Редактировать организацию',
                    children: [
                        {
                            id: 'active',
                            name: 'Блокировать',
                            desc: 'Позволяет временно заблокировать или разблокировать организацию'
                        },
                        {
                            id: 'common',
                            name: 'Общую информацию',
                            desc: 'Позволяет менять логотип организации, а так же другие нефинансовые сведения'
                        },
                        {
                            id: 'financial',
                            name: 'Финансовую информацию',
                            desc: 'Позволяет менять информацию связанную с гос.регистрацией (ИНН, ОКПО и т.д.), а так же данные для финансовых операций (номера счетов и т.д.)'
                        },
                    ]
                },
                {
                    id: 'delete',
                    name: 'Удалять организации',
                    desc: 'Удалять организации из системы'
                },
            ]
        },
        {
            id: 'user',
            name: 'Пользователи',
            children: [
                {
                    id: 'create',
                    name: 'Создавать аккаунты пользователей',
                    desc: 'Позволяет добавлять в систему новые аккаунты пользователей'
                },
                {
                    id: 'edit',
                    name: 'Редактировать аккаунт пользователя',
                    children: [
                        {
                            id: 'active',
                            name: 'Блокировать',
                            desc: 'Позволяет временно заблокировать или разблокировать аккаунт пользователя'
                        },
                        {
                            id: 'common',
                            name: 'Общую информацию',
                            desc: 'Позволяет менять аватар пользователя, имя, часовой пояс и т.д.'
                        },
                        {
                            id: 'system',
                            name: 'Системную информацию',
                            children: [
                                {
                                    id: 'password',
                                    name: 'Сменить пароль',
                                    desc: 'Позволяет поменять пароль пользователя на временный и выслать об этом уведомление на почту и т.д.'
                                },
                                {
                                    id: 'groups',
                                    name: 'Членство в группах',
                                    desc: 'Позволяет назначать/убирать пользователя в/из групп организаций'
                                },
                                {
                                    id: 'roles',
                                    name: 'Назначенные роли',
                                    desc: 'Позволяет назначать/убирать пользователя в/из ролей организаций'
                                },
                                {
                                    id: 'orgs',
                                    name: 'Членство в организациях',
                                    desc: 'Позволяет добавлять/убирать пользователя в/из организаций (если он не владелец организации)'
                                },
                            ]
                        },
                    ]
                },
                {
                    id: 'delete',
                    name: 'Удалять пользователей',
                    desc: 'Удалять аккаунты пользователей из системы'
                },
            ]
        },
    ]
};

export const PrivilegeTree: { [id: number]: PrivilegeTreeItem[] } = {
    [PrivilegeType.Person]:  [PrivilegesOrg, PrivilegesChat, PrivilegesFile, PrivilegesTask, PrivilegesCalendar],
    [PrivilegeType.Plugin]: [PrivilegesOrg, PrivilegesChat, PrivilegesFile, PrivilegesTask, PrivilegesCalendar, PrivilegesAuth],
    [PrivilegeType.All]: [PrivilegesOrg, PrivilegesChat, PrivilegesFile, PrivilegesTask, PrivilegesCalendar, PrivilegesAuth, PrivilegesAdmin],
};

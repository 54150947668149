import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';

import { asyncScheduler, BehaviorSubject, concat, Observable, of, throwError, timer } from 'rxjs';
import { catchError, finalize, switchMap, tap, filter, subscribeOn, retry, takeWhile, timeout, share } from 'rxjs/operators';

import { BaseModel } from '@models/base/base-model';
import { AccessTokenState } from '@models/api/access-token-state';
import { RequestCallbacks } from '@models/api/request-callbacks';
import { HttpApiError } from '@models/api/http-api-error';
import { SM } from '@models/base/helper-types';
import { removeFromArray } from '@models/utils/arrays';

export type TResponseType = 'arraybuffer' | 'blob' | 'json' | 'text';
export type TVzResponse = BaseModel | BaseModel[] | object | string | number | boolean | void | undefined;
export type TVzHttpParams = HttpParams | { [param: string]: string | string[] } | undefined;
export type TRequestParams<T> = {
    callbacks?: RequestCallbacks,
    body?: any,
    responseType?: TResponseType,
    httpParams?: TVzHttpParams,
    clazz?: typeof BaseModel,
    preParser?: (data: any) => any,
    parser?: (data: any, err: HttpApiError, response: any) => Observable<T>,
    hideFields?: string[],
    logRequest?: boolean,
    bypassSW?: boolean,
    headers?: HttpHeaders | { [id: string]: string | string[] },
};
export type VzServices = {
    http: HttpClient,
    baseUrl: string,
    L: (_fn: string, ..._x: any[]) => void,
    W: (_fn: string, ..._x: any[]) => void,
    getLastActivity: () => Date;
    getRefreshToken: () => string | undefined;
    getUserId: () => string | undefined;
    renewAuthToken: (userId: string, refreshToken: string, lastActivity: Date) => Observable<string>;
    getRefreshingAuthToken: () => Observable<string> | undefined;
    setRefreshingAuthToken: (obs?: Observable<string>) => void;
    gotNewToken: (token: string) => void;
    authTokenState: BehaviorSubject<AccessTokenState>;
    updateLastRequest: () => void,
    logRequest?: boolean
};

export const DEF_ERRORS: SM<string> = {
    200: 'Не удалось обработать ответ сервера',
    403: 'У вас нет доступа к этому объекту',
    404: 'Объект не найден',
    409: 'Конфликт данных',
};

export const DEFAULT_BODY_SIZE_LIMIT = 64;  // KBytes
export interface ApiMethod<IN extends unknown[] = [], OUT = void, BODY = any> {
    id?: string;
    method?: string;
    path?: string | ((...args: IN) => string);
    desc: string;
    baseUrl?: string;
    body?: BODY | ((...args: IN) => BODY),
    bodySizeLimit?: number,     // KBytes
    callbacks?: (...args: IN) => RequestCallbacks | undefined,
    hideFields?: string[],
    logRequest?: boolean,
    params?: TVzHttpParams | ((...args: IN) => TVzHttpParams),
    res: Readonly<{
        errorTitle: string | ((...args: IN) => string),
        errors?: SM<string | ((error: HttpApiError, ...args: IN) => string)>,
        clazz?: typeof BaseModel,
        preParser?: (data: any) => any,
        parser?: (data: any, err: HttpApiError, response: any) => Observable<OUT>,
        type?: TResponseType | ((...args: IN) => TResponseType),
    }>,
    bypassSW?: boolean,
    cfg?: string
}

export const Request = <IN extends unknown[] = [], OUT = void, BODY = any>(epId: string, method: string, path: string | ((...args: IN) => string), desc: ApiMethod<IN, OUT, BODY>) => {
    return (target: any, name: string, pd: PropertyDescriptor) => {
        if (!target.__RD) {
            target.__RD = {};
        }
        desc.id = epId;
        desc.method = method;
        desc.path = path;
        if (target.__RD[epId]) {
            console.warn('[@Request] duplicate ApiMethod description found! Ignoring.\n\t\tOriginal:', target.__RD[epId], '\n\t\tDuplicate:', desc);
        }
        else {
            target.__RD[epId] = desc;
        }
        return {
            get() {
                const wrapperFn = (...args: IN) => {
                    (this as any).__req = Object.assign({}, desc);
                    (pd.value as any).apply(this, args);
                    const res = (this as any).sendRequest.apply(this, [desc.id, ...args]);
                    (this as any).__req = null;
                    return res;
                }

                Object.defineProperty(this, name, {
                    value: wrapperFn,
                    configurable: true,
                    writable: true
                });
                return wrapperFn;
            }
        }
    };
};
export const Get = <IN extends unknown[] = [], OUT = void>(epId: string, path: string | ((...args: IN) => string), desc: ApiMethod<IN, OUT>): any => Request(epId, 'get', path, desc);
export const Post = <IN extends unknown[] = [], OUT = void, BODY = any>(epId: string, path: string | ((...args: IN) => string), desc: ApiMethod<IN, OUT, BODY>): any => Request<IN, OUT, BODY>(epId, 'post', path, desc);
export const Put = <IN extends unknown[] = [], OUT = void, BODY = any>(epId: string, path: string | ((...args: IN) => string), desc: ApiMethod<IN, OUT, BODY>): any => Request<IN, OUT, BODY>(epId, 'put', path, desc);
export const Delete = <IN extends unknown[] = [], OUT = void, BODY = any>(epId: string, path: string | ((...args: IN) => string), desc: ApiMethod<IN, OUT, BODY>): any => Request<IN, OUT, BODY>(epId, 'delete', path, desc);

export const EmulateRequest = (
    typeOrFn: 'get' | 'getList' | 'update' | 'create' | 'delete' | ((store: any, ...args: any) => any),
    storeId: string,
    opts?:{
        id: (...args: any) => string,
        obj?: (...args: any) => any,
        invalidate?: string
    }
) => {
    return (target: any, name: string, _pd: PropertyDescriptor) => {
        if (!target.__RDE) {
            target.__RDE = {};
        }
        target.__RDE[storeId] = {};
        return {
            get() {
                const wrapperFn = (...args: any[]) => {
                    console.log('===[ EmulateRequest ]=======>', typeOrFn, '<<<', ...args);
                    let res: any;
                    if (typeof typeOrFn == 'function') {
                        res = typeOrFn(target.__RDE[storeId], ...args);
                    }
                    else if (typeOrFn == 'getList') {
                        res = Object.values(target.__RDE[storeId]);
                    }
                    else if (typeOrFn == 'get' && opts?.id) {
                        res = target.__RDE[storeId][opts.id(...args)];
                        if (!res) {
                            return throwError(() => new HttpApiError('Emulated object not found', `storeId: ${storeId}, id: ${opts.id(...args)}`));
                        }
                    }
                    else if (typeOrFn == 'create' && opts?.id && opts?.obj) {
                        const id = opts.id(...args);
                        res = opts.obj(...args);
                        res.id = id;
                        target.__RDE[storeId][id] = res;
                    }
                    else if (typeOrFn == 'update' && opts?.id && opts?.obj) {
                        const obj = target.__RDE[storeId][opts.id(...args)];
                        if (!obj) {
                            return throwError(() => new HttpApiError('Emulated object not found', `storeId: ${storeId}, id: ${opts.id(...args)}`));
                        }
                        res = Object.assign(obj, opts.obj(...args));
                        target.__RDE[storeId][opts.id(...args)] = res;
                    }
                    else if (typeOrFn == 'delete' && opts?.id) {
                        delete target.__RDE[storeId][opts.id(...args)];
                    }
                    console.log('===[ EmulateRequest ]=======>', typeOrFn, '>>>\n\t\tRES:', res, '\n\t\t__RDE:', target.__RDE[storeId]);
                    if (opts?.invalidate) {
                        (this as any).invalidateStore.apply(this, [opts.invalidate]);
                    }
                    return of(res);
                }

                Object.defineProperty(this, name, {
                    value: wrapperFn,
                    configurable: true,
                    writable: true
                });
                return wrapperFn;
            }
        }
    };
};

export const EmulatorStore: ((storeId: string, data: any) => ClassDecorator) = (storeId: string, data: any) => {
    return component => {
        if (!component.prototype.__RDE) {
            component.prototype.__RDE = {};
        }
        component.prototype.__RDE[storeId] = data;
    };
}


export function parseArray<T extends BaseModel>(type: new (json?: any) => T, array: any): T[] | undefined {
    if (!array) {
        return undefined;
    }
    const arr: T[] = [];
    for (const obj of array) {
        arr.push(new type(obj) as T);
    }
    return arr;
}

export function emitArray<T extends BaseModel>(
    type: new (json?: any) => T
): (data: any, err: HttpApiError, response: any) => Observable<T[]> {
    return data => of(parseArray(type, data) || []);
}

export function emitField<T extends BaseModel | string | number | boolean | void>(
    fn: string,
    optional = false
): (data: any, err: HttpApiError, response: any) => Observable<T> {
    return (data, err) => {
        if (!optional && (!data || data[fn] === undefined)) {
            return throwError(() => err);
        }
        else {
            return of(data?.[fn]);
        }
    };
}

export function emitOptional<T extends BaseModel>(
    type: new (json?: any) => T
): (data: any, err: HttpApiError, response: any) => Observable<T | undefined> {
    return data => of(data ? new type(data) : undefined);
}

export function getHttpParams(paramsObject: any): HttpParams | undefined {
    if (!paramsObject) {
        return;
    }
    const obj: any = {};
    Object.keys(paramsObject).forEach(key => {
        if (key && paramsObject[key] !== undefined) {
            obj[key] = paramsObject[key];
        }
    });
    return new HttpParams({ fromObject: obj });
}

export function batchReq(
    ids: string[],
    apiCall: (id: string) => Observable<any>,
    err: (errors: HttpApiError[]) => void,
    ok?: (ids: string[]) => void,
    timeout?: number,
): void {
    const errors: HttpApiError[] = [];
    const oks: string[] = [...ids];
    const reqs = ids.map((rid, i) => apiCall(rid).pipe(
        subscribeOn(asyncScheduler, i > 0 ? timeout || 20 : 0),
        catchError((err) => {
            errors.push(err);
            removeFromArray(oks, oid => oid == rid);
            return of();
        })
    ));
    concat(...reqs).pipe(
        finalize(() => {
            if (errors.length > 0) {
                err(errors);
                if (oks.length > 0 && ok) {
                    ok(oks);
                }
            }
            else if (ok) {
                ok(oks);
            }
        })
    ).subscribe();
}

export function batchGet<T>(
    ids: string[],
    opts: {
        key?: string,
        fn: (id: string) => Observable<T | undefined>,
        timeout?: number,
        res?: (om: SM<T | null>, errors?: SM<HttpApiError>) => void,
        ok?: (id: string, obj: T | null) => void,
        err?: (id: string, err: HttpApiError) => void,
    }
): void {
    const errors: SM<HttpApiError> = {};
    const oks: SM<T | null> = {};
    const reqs = ids.map((rid, i) => opts.fn(rid).pipe(
        subscribeOn(asyncScheduler, i > 0 ? opts.timeout || 20 : 0),
        catchError((err) => {
            errors[rid] = err;
            oks[rid] = null;
            opts.ok?.(rid, null);
            opts.err?.(rid, err);
            return of();
        }),
        tap(obj => {
            oks[rid] = obj || null;
            opts.ok?.(rid, oks[rid]);
        })
    ));
    concat(...reqs).pipe(finalize(() => opts.res?.(oks, errors))).subscribe();
}

export function request<T extends BaseModel | BaseModel[] | string | number | boolean | object | void | null | undefined>(
    service: VzServices,
    method: string,
    url: string,
    errorTitle: string,
    params?: TRequestParams<T>
): Observable<T> {
    // service.L('request', `[${method}]`, url, params);
    let httpParams = params?.httpParams;
    if (params?.bypassSW) {
        if (httpParams) {
            if (httpParams instanceof HttpParams) {
                httpParams = httpParams.set('ngsw-bypass' , '1');
            }
            else if (typeof httpParams == 'object') {
                httpParams['ngsw-bypass'] = '1';
            }
            else {
                httpParams = { 'ngsw-bypass': '1' };
            }
        }
        else {
            httpParams = { 'ngsw-bypass': '1' };
        }
    }
    const reqOptions = {
        withCredentials: false,
        observe: 'events' as const,
        body: params?.body ? params.body : undefined,
        reportProgress: !!(params?.callbacks?.upload || params?.callbacks?.download || params?.callbacks?.requestId),
        params: httpParams,
        responseType: params?.responseType,
        headers: params?.headers
    };
    if (service.logRequest || params?.logRequest) {
        service.L(`request[${method}] ${url} reqOptions:`, reqOptions);
    }

    return service.http.request(method, service.baseUrl + url, reqOptions).pipe(
        takeWhile(() => service.authTokenState.getValue() < AccessTokenState.ExpiredErrorTemp),
        retry({ delay: (error: any, count: number) => {
            if (error.status != 401 || count > 3) {
                if (error.status >= 500 && count < 3) {
                    service.L(`request[${method}] ${url} reqError:`, error, `\n\twaiting for ${count * 2}s to retry`);
                    return timer(1000 * count * 2);
                }
                const err = new HttpApiError(errorTitle).parse(error, method, params?.body || params?.httpParams, params?.hideFields);
                if (service.logRequest || params?.logRequest) {
                    service.W(`request[${method}] ${url} reqError:`, err, '\n\torigError:', error);
                }
                return throwError(() => err);
            }
            const refreshing = service.getRefreshingAuthToken();
            if (refreshing) {
                service.L(`[${method} ${url}] AuthToken is already being refreshed, waiting for completion max 10s...`);
                return refreshing.pipe(
                    timeout(10000),
                    catchError(
                        () => throwError(
                            () => new HttpApiError(errorTitle).parse(error, method, params?.body || params?.httpParams, params?.hideFields)
                                .setTitle('Сервис Vizorro временно недоступен')
                                .setText('')
                                .setType('info')
                        )
                    )
                );
            }
            service.W('AuthToken expired, refreshing...');
            const refreshToken = service.getRefreshToken();
            if (refreshToken) {
                // const ndt = new Date().getTime();
                // if (service._auth.refreshTokenTime.length > 2) {
                //     if (service._auth.refreshTokenTime[0] + 5 * 1000 > ndt) {
                //         service.W('Too many token refreshes, return error.');
                //         return throwError(() => new HttpApiError(errorTitle).parse(error, method, params?.body || params?.httpParams, params?.hideFields));
                //     }
                // }
                // service._auth.refreshTokenTime.push(ndt);
                // while (service._auth.refreshTokenTime.length > 3) {
                //     service._auth.refreshTokenTime.shift();
                // }
                service.authTokenState.next(AccessTokenState.ExpiredRefreshing);
                const rat = refreshAuthToken(service.getUserId(), refreshToken, service);
                service.setRefreshingAuthToken(rat);
                return rat;
            }
            service.W('AuthToken refreshing failed, RefreshToken is empty');
            service.authTokenState.next(AccessTokenState.ExpiredErrorPermanent);
            return throwError(() => new HttpApiError(errorTitle).parse(error, method, params?.body || params?.httpParams, params?.hideFields));
        }}),
        tap(event => {
            if (event.type == HttpEventType.Sent) {
                service.updateLastRequest();
            }
            else if (params?.callbacks?.requestId && event.type == HttpEventType.ResponseHeader) {
                const reqId = event.headers.get('x-vizorro-requestid') || '';
                params.callbacks.requestId(reqId);
            }
            else if (params?.callbacks?.upload && event.type == HttpEventType.UploadProgress) {
                params.callbacks.upload(event);
            }
            else if (params?.callbacks?.download && event.type == HttpEventType.DownloadProgress) {
                params.callbacks.download(event);
            }
        }),
        filter(event => event.type == HttpEventType.Response),
        switchMap(event => params?.preParser ? of(params.preParser(event)) : of(event)),
        switchMap(event => params?.parser
            ? params.parser(event.body, new HttpApiError(errorTitle, 'Ошибка обработки ответа сервера').parse(event, method, params?.body, params?.hideFields), event)
            : of(params?.clazz ? new params.clazz(event.body) : event.body)
        ),
    );
}

export const sendRequest = <OUT extends TVzResponse, IN extends unknown[] = []>(
    rp: ApiMethod<IN, OUT>,
    cfg: VzServices  | ((...args: IN) => VzServices),
    ...args: IN
): Observable<OUT> => {
    const errorTitle = typeof rp.res.errorTitle == 'function' ? rp.res.errorTitle(...args) : rp.res.errorTitle;
    const body = typeof rp.body == 'function' ? rp.body(...args) : rp.body!;
    const ED = { ...DEF_ERRORS, ...(rp.res.errors || {}) };
    if (body && rp.bodySizeLimit != -1) {
        const bsl = (rp.bodySizeLimit || DEFAULT_BODY_SIZE_LIMIT) * 1024;
        const sb = JSON.stringify(body);
        if (sb && sb.length > bsl / 3) {
            const bs = new Blob([sb]).size;
            if (bs > bsl) {
                const service = typeof cfg == 'function' ? cfg(...args) : cfg;
                service.W('sendRequest', `request bodySizeLimit(${rp.bodySizeLimit}) exceeded: ${sb.length} > ${bsl / 3}`);
                const e = ED?.[413] || ED?.['_'];
                const err = new HttpApiError(errorTitle, `Превышен максимальный размер данных для отправки`);
                if (e != null) {
                    err.setText(typeof e == 'function' ? e(err, ...args) : (err.error?.text || e)).setType('info');
                }
                return throwError(() => err);
            }
        }
    }
    const req = request(
        typeof cfg == 'function' ? cfg(...args) : cfg,
        rp.method!,
        typeof rp.path == 'function' ? rp.path(...args) : rp.path!,
        errorTitle,
        {
            callbacks: rp.callbacks?.(...args),
            body,
            responseType: typeof rp.res.type == 'function' ? rp.res.type(...args) : rp.res.type,
            httpParams: typeof rp.params == 'function' ? rp.params(...args) : rp.params!,
            clazz: rp.res.clazz,
            preParser: rp.res.preParser,
            parser: rp.res.parser,
            hideFields: rp.hideFields,
            logRequest: rp.logRequest,
            bypassSW: rp.bypassSW,
        }
    );
    return Object.values(ED).length
        ? req.pipe(catchError(error => {
            const err: HttpApiError = error instanceof HttpApiError ? error : new HttpApiError(errorTitle, error);
            const e = ED?.[err.status] || ED?.['_'];
            if (e != null) {
                err.setText(typeof e == 'function' ? e(err, ...args) : (err.error?.text || e)).setType('info');
            }
            throw err;
        }))
        : req;
}

export function refreshAuthToken(
    userId: string | undefined,
    refreshToken: string,
    service: VzServices
): Observable<string> {
    service.L('refreshAuthToken', '=======> Start');
    return new Observable<string>(obs => {
        service.renewAuthToken(userId!, refreshToken, service.getLastActivity()).pipe(
            retry({
                delay: (error: any, count: number) => {
                    if (error.status == 400 || error.status == 401 || error.status == 403) {
                        return throwError(() => error);
                    }
                    else if (count > 5) {
                        service.authTokenState.next(error.status == 0 ? AccessTokenState.ExpiredErrorTempNetwork : AccessTokenState.ExpiredErrorTemp);
                        return throwError(() => '');
                    }
                    else {
                        const delay = Math.pow(2, count - 1) * 500;
                        service.L(`Non-fatal AuthToken refresh error, waiting for ${delay}ms to retry...`);
                        return timer(delay);
                    }
                }
            })
        ).subscribe({
            next: token => {
                service.gotNewToken(token);
                service.L('refreshAuthToken', 'AuthToken has been refreshed');
                service.authTokenState.next(AccessTokenState.Good);
                obs.next(token);
                obs.complete();
            },
            error: e => {
                service.W('refreshAuthToken', 'AuthToken refreshing failed, error:', e ? e : 'temporary network error');
                if (e && e.status >= 400 && e.status < 500) {
                    service.authTokenState.next(AccessTokenState.ExpiredErrorPermanent);
                    obs.error(e);
                }
                else {
                    service.authTokenState.next(AccessTokenState.ExpiredErrorTempNetwork);
                    obs.next('-empty-');
                    obs.complete();
                }
            }
        });
    }).pipe(
        finalize(() => {
            service.setRefreshingAuthToken();
            service.L('refreshAuthToken', '<====== Stop');
        }),
        share()
    );
}
